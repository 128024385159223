import { useQuery, queryCache } from "react-query";
import { apiRequest } from "./util";

export function findOrCreateUser(id, source, email, raw) {
  return apiRequest("account", "POST", { id, source, email, raw });
}

export function getAccount() {
  const query = () => apiRequest(`account`);
  return useQuery('account', query);
}

export function updateSettings(settings) {
  return apiRequest('account/settings', "PUT", settings).then((result) => {
    queryCache.refetchQueries('account', { force: true });
    return result;
  });
}

export function updatePage(page) {
  return apiRequest("page", "PUT", page).then((result) => {
    queryCache.refetchQueries('pages', { force: true });
    return result;
  });
}

export function updatePageStatus(pageId, slug, status) {
  return apiRequest(`page/${pageId}/status`, "PUT", { status: status }).then((result) => {
    queryCache.refetchQueries('pages', { force: true });
    queryCache.refetchQueries('page' + slug, { force: true });
    return result;
  });
}

export function bulkUpdatePageStatus(ids, status) {
  return apiRequest(`page/status`, "POST", { pageIds: ids, status: status }).then((result) => {
    queryCache.refetchQueries('pages', { force: true });
    return result;
  });
}

export function updateContact(contact) {
  return apiRequest("account", "PUT", contact).then((result) => {
    queryCache.refetchQueries('account', { force: true });
    return result;
  });
}

export function getPages(offset, limit, filter, contentType) {
  const query = () => apiRequest(`page?offset=${offset}&limit=${limit}&filter=${filter}&ct=${contentType}`);
  return useQuery(['pages', { offset: offset, limit: limit, filter: filter, contentType: contentType }], query);
}

export function getPage(slug) {
  const query = () => apiRequest(`page/${slug}`);
  return useQuery(['pages', { slug: slug }], query);
}

export function getSuggestions(offset, limit, filter) {
  const query = () => apiRequest(`suggestion?offset=${offset}&limit=${limit}&filter=${filter}`);
  return useQuery(['suggestions', { offset: offset, limit: limit, filter: filter }], query);
}

export function updateSuggestionStatus(keyword, status) {
  return apiRequest(`suggestion/${keyword}/status`, "PUT", { status: status }).then((result) => {
    queryCache.refetchQueries('suggestions', { force: true });
    return result;
  });
}

export function bulkUpdateSuggestionStatus(keywords, status) {
  return apiRequest(`suggestion/status`, "POST", { keywords: keywords, status: status }).then((result) => {
    queryCache.refetchQueries('suggestions', { force: true });
    return result;
  });
}

export function getComponents(offset, limit) {
  const query = () => apiRequest(`component?offset=${offset}&limit=${limit}`);
  return useQuery(['components', { offset: offset, limit: limit }], query);
}

export function getComponent(id) {
  const query = () => apiRequest(`component/${id}`);
  return useQuery(['components', { componentId: id }], query);
}

export function updateComponent(component) {
  return apiRequest("component", "PUT", component).then((result) => {
    queryCache.refetchQueries('components', { force: true });
    return result;
  });
}

export function getContentTemplates(offset, limit) {
  const query = () => apiRequest(`contenttemplate?offset=${offset}&limit=${limit}`);
  return useQuery(['contenttemplates', { offset: offset, limit: limit }], query);
}

export function getContentTemplate(id) {
  const query = () => apiRequest(`contenttemplate/${id}`);
  return useQuery(['contenttemplates', { contentTemplateId: id }], query);
}

export function updateContentTemplate(contentTemplate) {
  return apiRequest("contenttemplate", "PUT", contentTemplate).then((result) => {
    queryCache.refetchQueries('contenttemplates', { force: true });
    return result;
  });
}

export function getSample(settings) {
  return apiRequest(`sample`, 'post', settings);
}

export function getCompletion(settings) {
  return apiRequest(`compose`, 'post', settings);
}

export function semanticSearchProducts(settings) {
  return apiRequest(`semsearch`, 'post', settings);
}

export function getProductContent(skus) {
  return apiRequest(`product/content`, 'post', skus);
}

export function setProductContent(sku, key, value) {
  return apiRequest("product/content", "PUT", { sku: sku, key: key, value: value }).then((result) => {
    queryCache.refetchQueries('products', { force: true });
    return result;
  });
}

export function composeProductContent(product, copyType) {
  return apiRequest(`product/${product.sku}/compose`, 'post', { product: product, copyType: copyType }).then((result) => {
    queryCache.refetchQueries('products', { force: true });
    return result;
  });
}

export function getTopicSuggestions(contentTemplateId, niche, sampleTitles, youtubeChannel) {
  return apiRequest('topic', 'post', { contentTemplateId: contentTemplateId, niche: niche, sampleTitles: sampleTitles, youtubeChannel: youtubeChannel });
}

export function getInstagramCaption(username, niche, topic) {
  return apiRequest(`instagram`, 'post', { username: username, niche: niche, topic: topic });
}

export function getTweet(username, niche, topic) {
  return apiRequest(`twitter`, 'post', { username: username, niche: niche, topic: topic });
}

export function getYouTubeTitles(username, theme) {
  return apiRequest(`unblock/youtube`, 'post', { username: username, topic: theme });
}